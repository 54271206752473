/* * {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
} */
body,
html {
  position: fixed;
  width: 100%; 
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-button {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  background: #aa8564;
  border: 2px none #ffffff;
  border-radius: 50px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background: #a38060;
}
::-webkit-scrollbar-thumb:active {
  background: #a38060;
}
::-webkit-scrollbar-track {
  background: #e6e6e6;
  border: 0px none #e6e6e6;
  border-radius: 50px;
  cursor: pointer;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

@media only screen and (min-width: 773px) and (max-width: 1024px) {
  .notTablet {
    display: none;
  }
}
@media only screen and (min-width: 773px) {
  .onlyMobile {
    display: none;
  }
  .onlyDesktop {
    display: block;
  }
}
@media only screen and (max-width: 772px) {
  .onlyMobile {
    display: block;
  }
  .onlyDesktop {
    display: none;
  }
}
