.App {
  text-align: center;
  background-color: rgba(239, 239, 239, 1);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}

.configuratorWrapper {
  height: calc(100vh - 54px);
  margin-top: 54px;
  width: 100%;
  /* padding-top: 54px; */
  /* box-sizing: border-box; */
  overflow: hidden;
  position: relative;
  display: flex;
  user-select: none;
}

i {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 0px 5px 0px 5px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.contactText a {
  color: #000;
  text-decoration: none;
}
.contactText a:hover {
  text-decoration: underline;
}
